<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// Swiper.js
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// vue components
import gotop from './components/Gotop.vue';
import aco from './components/Aco.vue';

export default {
    inject: ["mq"],

    components: {
        Swiper,
        SwiperSlide,
        gotop,
        aco,
    },

    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            // スライダー設定
            swiper: {
                modules: [Pagination, Autoplay, Navigation],
                options: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    common01: {
                        autoplay: {
                            delay: 4000,
                            disableOnInteraction: false,
                        },
                    },
                    common02: {
                        autoplay: {
                            delay: 4300,
                            disableOnInteraction: false,
                        },
                    },
                    common03: {
                        autoplay: {
                            delay: 4600,
                            disableOnInteraction: false,
                        },
                    },
                    loop: true,
                },
            }
        };
    },

    data() {
        return {
            hash: location.hash, //URLハッシュ
            headerH: 0, //ヘッダーの高さ
            headerShow: false, //ヘッダー表示管理（トップページ）
            gnav: {
                isActive: false
            },
        }
    },

    computed: {
        // フォーム　確認/完了画面かどうか
        isConfirm() {
            if (this.hash === '#wpcf7-f11-o1') return true;
        },
    },

    methods: {
        // wow.js初期化
        wowInit() {
            const wow = new WOW({
                offset: this.mq.mdMinus ? 100 : 300, // SP : PC
                animateClass: 'animate__animated'
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            const header = this.$refs.header;
            const getVal = () => {
                this.headerH = header.getBoundingClientRect().height;
            }
            window.addEventListener('load', getVal);
            window.addEventListener('resize', getVal);
        },

        // アンカーリンク
        anchorLink(hash) {
            if (!document.getElementById(hash)) return;
            this.$scrollTo(`#${hash}`, 500, {
                offset: -this.headerH - 20, //ヘッダー分ずらす
                onStart: this.gnav.isActive = false //グロナビを閉じる
            });
        },

        // URLにハッシュがあればページ読み込み時にスクロール
        loadScroll() {
            if (this.isConfirm) return; //フォーム確認/完了画面では発火しない

            if (this.hash && this.hash.match(/^#.+$/)) {
                const target = this.hash.replace('#', '');
                window.onload = () => {
                    this.anchorLink(target);
                }
            }
        },

        // フォーム確認/完了画面のスクロール位置調整
        scrollInvaildContact() {
            const btn = document.querySelector('.js-form-btn'); //ボタン
            if (!this.isConfirm || !btn) return;

            const scroll = () => {
                this.anchorLink('contact');
            }
            window.addEventListener('load', scroll); //ロード時
            btn.addEventListener('click', scroll); //ボタンクリック時
        },

        // ハンバーガーメニューの切り替え
        toggleIsActive() {
            this.gnav.isActive = !this.gnav.isActive;
        },

        // ヘッダー表示（トップページ）
        headerFadeIn() {
            const header = this.$refs.header; //ヘッダー要素
            if (!header.classList.contains('is-frontpage')) return; //トップページでなければ終了

            window.addEventListener('scroll', () => {
                // 発火ポイントを取得
                const pointEl = this.mq.smMinus ? this.$refs.spPoint : this.$refs.pcPoint;
                const rect = pointEl.getBoundingClientRect().top + window.scrollY;
                const offset = this.mq.smMinus ? rect - 100 : rect - 200;
                
                // offset以上で実行
                if (window.scrollY > offset) {
                    this.headerShow = true; //ヘッダー表示
                
                // offset以内で実行
                } else {
                    this.headerShow = false; //ヘッダー非表示
                }
            });
        },

        // 一度クローズボタンを押されたら15分間非表示
        activeMemberBtn() {
            const closeBtn = document.querySelector('.membership-btn__close');
            const membershipBtn = document.querySelector('.membership-btn');

            closeBtn.addEventListener('click', () => {
                // クッキーを設定（15分間有効）
                document.cookie = "hide_membership_btn=true; max-age=900; path=/";
                
                // フェードアウト効果を適用
                membershipBtn.classList.add('fade-out');
                
                // アニメーション終了後に非表示にする
                membershipBtn.addEventListener('transitionend', () => {
                    membershipBtn.style.display = 'none';
                }, { once: true });
            });

            // ページ読み込み時にクッキーをチェック
            if (document.cookie.indexOf('hide_membership_btn=true') !== -1) {
                membershipBtn.style.display = 'none';
            }
        }
    },

    mounted() {
        this.wowInit();
        this.getHeaderH();
        this.headerFadeIn();
        this.loadScroll();
        this.activeMemberBtn();
    },

}
</script>